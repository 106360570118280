import { Form } from 'antd';

import TRPCLayout from '../../../utils/TRPCLayout';

export default () => {
	const [form] = Form.useForm();
	const pic = Form.useWatch('pic', form);

	return <TRPCLayout app="apartment">123</TRPCLayout>;
};
